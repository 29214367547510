.errorText {
  text-align: center;
  color: red;
}

.itemCard {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.none {
  text-align: center;
  width: 100%;
}