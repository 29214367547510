.download {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;

    a {
        padding-left: 10px;
    }
}

.buttons {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    gap: 60px;
}

.loading {
    min-height: 600px;
}