@use "../assets/scss/style";
@import '../assets/scss/themes-vars.module.scss';

.failureReasons {
  border: 1px solid style.$secondaryDark;
  border-radius: 4px;
  width: 100%;
  padding: 0;
  margin: 1rem;
  text-align: start;
  font-weight: bold;
}

.ineligibileText {
  color: $errorDark;
  text-align: left;
  margin: 1.5rem;
}

.existingUserLoginButton {
  background-color: style.$primaryMain;
  margin: 10px;
}

@mixin responseBanner {
  padding: 20px;
  margin-left: 1.5rem;
  display: flex;
  justify-content: center;
  vertical-align: center;
  margin-top: 1rem;
}
  .existingUserBanner {
    @include responseBanner;
    background-color: style.$darkTextSecondary;

  }
  .existingUserLoggedInBanner {
    @include responseBanner;
    background-color: style.$errorMain;
  }
  .newUserBanner {
    @include responseBanner;
    background-color: style.$primaryMain;
  }

  .instructionsText {
    display: flex;
    color: white;
    font-family: Roboto, system-ui;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    margin-left: 1.5rem;
    margin-right: 2rem;
    justify-content: center;
    vertical-align: center;
  }

  .eligibleIcon {
    color:white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: center;
  }

@mixin eligibleText {
  text-align: left;
  margin: 1.5rem;
}
.existingUserLoggedInText {
  @include eligibleText;
  color: style.$errorMain;
}

.newUserEligibleText {
  @include eligibleText;
  color: style.$primaryMain;
}

.existingUserEligibleText {
  @include eligibleText;
  color: style.$darkTextSecondary;
}


.otherSupportsText {
  text-align: left;
}

.button-existingUserLoggedIn {
  color: style.$errorMain;
}
