.answer {
    margin-top: 20px;
}

.questionGroupHeading {
    color: #953372;
    margin-top: 30px;
}

.introText {
    margin-bottom: 40px;
}