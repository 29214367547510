@import '../assets/scss/themes-vars.module.scss';

.eftInput {
  margin-right: 2rem;
  margin-bottom: 1rem;

  &.name {
    width: 200px;
  }

  &.institution {
    width: 120px;
  }
}

.submitButton {
  margin: 1rem 0;
}

.bold {
  font-weight: bold;
}

.heading {
  margin: 1rem 0;
}

.fieldset {
  border-radius: 4px;
  border-color: $grey100;
  max-height: 190px;
  overflow-y: auto;
  margin-bottom: 24px;

  .legend {
    color: $grey500;
  }

  ul.sublist {
    list-style-type: lower-roman;
  }
}