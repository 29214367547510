.incomeCardHeading {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.incomeContainer {
	margin-top: 16px;
	margin-bottom: 64px;
}

.incomeSection {
	margin-bottom: 16px;
}

.incomeSectionHeader {
	padding: 4px;
	background-color: #953372;
}

.incomeRow {
	margin-bottom: 16px;
}

.excludedSource {
  color: red;
}