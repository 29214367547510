.card {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 1.5rem;
}

.cardSlim {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.cardHeading {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.cardItem {
    padding-right: 1rem;
    margin-bottom: 1rem;
}
