.navigationButtons {
  margin: 2rem 0;
}

.pointScore {
  font-size: 25px;
  font-weight: bold;
  color: #953372;
}

.submissionThankYou {
  color: #953372;
  margin-top: 25px;
  margin-bottom: 25px;
}

.saveAndExit {
  margin-left: 2rem;
  text-transform: uppercase;
}

.loader {
  display: flex;
  justify-content: center;
  margin-top: 200px;
  color: #953372;
}

.link {
  padding-left: 1ch;
}