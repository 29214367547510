.panel {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
  margin-left: 23px;
}

.panelLabel {
  margin-top: -33px;
  background: white;
  max-width: fit-content;
  font-weight: bold;
}

.text {
  overflow:auto;
  display:block;
  max-height:200px;
  padding-top: 20px;
}

.plainList {
  >li{
    list-style: none;
  }
}