// paper & background
$paper: var(--paper) or #ffffff;

// primary
$primaryLight: var(--primaryLight) or #fffff;
$primaryMain: var(--primaryMain) or #fffff;
$primaryDark: var(--primaryDark) or #ffffff;
$primary200: var(--primary200) or #ffffff;
$primary800: var(--primary800) or #ffffff;

// secondary
$secondaryLight: var(--secondaryLight) or #ffffff;
$secondaryMain: var(--secondaryMain) or #ffffff;
$secondaryDark: var(--secondaryDark) or #ffffff;
$secondary200: var(--secondary200) or #ffffff;
$secondary800: var(--secondary800) or #ffffff;

// Annual Review
$annualReviewLight: var(--annualReviewLight) or #ffffff;
$annualReviewMain: var(--annualReviewMain) or #ffffff;
$annualReviewDark: var(--annualReviewDark) or #ffffff;
$annualReview200: var(--annualReview200) or #ffffff;
$annualReview800: var(--annualReview800) or #ffffff;

// Interim Review
$interimReviewLight: var(--interimReviewLight) or #ffffff;
$interimReviewMain: var(--interimReviewMain) or #ffffff;
$interimReviewDark: var(--interimReviewDark) or #ffffff;
$interimReview200: var(--interimReview200) or #ffffff;
$interimReview800: var(--interimReview800) or #ffffff;

// success Colors
$successLight: var(--successLight) or #ffffff;
$success200: var(--success200) or #ffffff;
$successMain: var(--successMain) or #ffffff;
$successDark: var(--successDark) or #ffffff;

// error
$errorLight: var(--errorLight) or #ffffff;
$errorMain: var(--errorMain) or #ffffff;
$errorDark: var(--errorDark) or #ffffff;

// orange
$orangeLight: var(--orangeLight) or #ffffff;
$orangeMain: var(--orangeMain) or #ffffff;
$orangeDark: var(--orangeDark) or #ffffff;

// warning
$warningLight: var(--warningLight) or #ffffff;
$warningMain: var(--warningMain) or #ffffff;
$warningDark: var(--warningDark) or #ffffff;

// grey
$grey50: var(--grey50) or #ffffff;
$grey100: var(--grey100) or #ffffff;
$grey200: var(--grey200) or #ffffff;
$grey300: var(--grey300) or #ffffff;
$grey500: var(--grey500) or #ffffff;
$grey600: var(--grey600) or #ffffff;
$grey700: var(--grey700) or #ffffff;
$grey900: var(--grey900) or #ffffff;

$headerBackgroundImage:  var(--headerBackgroundImage) or #ffffff;
$rightSideCard: var(--rightSideCard);

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: var(--darkBackground); // level 3
$darkPaper: var(--darkPaper); // level 4

// dark 800 & 900
$darkLevel1: var(--darkLevel1); // level 1
$darkLevel2: var(--darkLevel2); // level 2

// primary dark
$darkPrimaryLight: var(--darkPrimaryLight);
$darkPrimaryMain: var(--darkPrimaryMain);
$darkPrimaryDark: var(--darkPrimaryDark);
$darkPrimary200: var(--darkPrimary200);
$darkPrimary800: var(--darkPrimary800);

// secondary dark
$darkSecondaryLight: var(--darkSecondaryLight);
$darkSecondaryMain: var(--darkSecondaryMain);
$darkSecondaryDark: var(--darkSecondaryDark);
$darkSecondary200: var(--darkSecondary200);
$darkSecondary800: var(--darkSecondary800);

// text variants
$darkTextTitle: var(--darkTextTitle);
$darkTextPrimary: var(--darkTextPrimary);
$darkTextSecondary: var(--darkTextSecondary);

// ==============================|| JAVASCRIPT ||============================== //

:export {
	//global variables that have theme-specific values
	--headerBackgroundImage: $headerBackgroundImage;
	--rightSideCard: $rightSideCard;

	// paper & background
	paper: $paper;

	// primary
	primaryLight: $primaryLight;
	primary200: $primary200;
	primaryMain: $primaryMain;
	primaryDark: $primaryDark;
	primary800: $primary800;

	// secondary
	secondaryLight: $secondaryLight;
	secondary200: $secondary200;
	secondaryMain: $secondaryMain;
	secondaryDark: $secondaryDark;
	secondary800: $secondary800;

	// success
	successLight: $successLight;
	success200: $success200;
	successMain: $successMain;
	successDark: $successDark;

	// error
	errorLight: $errorLight;
	errorMain: $errorMain;
	errorDark: $errorDark;

	// orange
	orangeLight: $orangeLight;
	orangeMain: $orangeMain;
	orangeDark: $orangeDark;

	// warning
	warningLight: $warningLight;
	warningMain: $warningMain;
	warningDark: $warningDark;

	// grey
	grey50: $grey50;
	grey100: $grey100;
	grey200: $grey200;
	grey300: $grey300;
	grey500: $grey500;
	grey600: $grey600;
	grey700: $grey700;
	grey900: $grey900;
	
	// ==============================|| DARK THEME VARIANTS ||============================== //

	// paper & background
	darkPaper: $darkPaper;
	darkBackground: $darkBackground;

	// dark 800 & 900
	darkLevel1: $darkLevel1;
	darkLevel2: $darkLevel2;

	// text variants
	darkTextTitle: $darkTextTitle;
	darkTextPrimary: $darkTextPrimary;
	darkTextSecondary: $darkTextSecondary;

	// primary dark
	darkPrimaryLight: $darkPrimaryLight;
	darkPrimaryMain: $darkPrimaryMain;
	darkPrimaryDark: $darkPrimaryDark;
	darkPrimary200: $darkPrimary200;
	darkPrimary800: $darkPrimary800;

	// secondary dark
	darkSecondaryLight: $darkSecondaryLight;
	darkSecondaryMain: $darkSecondaryMain;
	darkSecondaryDark: $darkSecondaryDark;
	darkSecondary200: $darkSecondary200;
	darkSecondary800: $darkSecondary800;	
}
