@use "assets/scss/themeProvider.module";

.confirmationBox {
  display: flex;
  border: 2px solid themeProvider.$primaryMain;
  border-top-width: 2rem;
  border-radius: 5px;
  width: 260px;
  height: 120px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
}

.confirmationBoxHeadingText {
  position: relative;
  top: -2.25rem;
}